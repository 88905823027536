import React, { useState } from 'react';
import { graphql, withPrefix } from 'gatsby';

import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { Location } from '../models';
import EmailPopup from '../components/email-popup';
import ContactForm from '../components/contact-form';
import Seo from '../components/seo'

interface Props {
  location: Location;
  data: {
    allConfig: {
      nodes: { name: string; value: string }[];
    };
  };
}

const Contact = ({ data, location }: Props) => {
  // const handleSubmit = (e: React.SyntheticEvent) => {
  //   setFormState('pending');
  //   fetch(BACKEND_URL, {
  //     method: 'POST',
  //     body: JSON.stringify({ name, email, organization, message }),
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then(() => setFormState('sent'))
  //     .catch(() => setFormState('sent'));
  //   e.preventDefault();
  // };

  return (
    <div id="main_content">
      <Seo title='Contact'/>
      <Navbar dark={true} />
      <EmailPopup />

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Contact Us!</h1>

            <ul className="bradcurmed">
              <li>
                <a href={withPrefix("/")} rel="noopener noreferrer">
                  Home
                </a>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>
      <section className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact-infos">
                <div className="contact-info">
                  <h3 className="title">Our Location</h3>
                  <p className="description">
                    Hársfa utca 122.
                    <br />
                    1162 Budapest, Hungary
                  </p>

                  <div className="info phone">
                    <i className="ei ei-icon_phone"></i>
                    <span>+36 1 242 1671</span>
                  </div>
                </div>

                <div className="contact-info">
                  <h3 className="title">Say Hello!</h3>
                  <div className="info">
                    <i className="ei ei-icon_mail_alt"></i>
                    <span>info@skillgo.io</span>
                  </div>
                </div>
              </div>
            </div>

            <ContactForm usedClassName="col-md-8"/>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;

// TEMPORARY BUILD FIX, THIS WAS THE ORIGINAL:
/* export const pageQuery = graphql`
 *   query {
 *     site {
 *       siteMetadata {
 *         title
 *       }
 *     }
 *     allConfig(filter: { name: { eq: "BACKEND_URL" } }) {
 *       nodes {
 *         name
 *         value
 *       }
 *     }
 *   }
 * `; */
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allConfig(filter: { name: { eq: "BACKEND_URL" } }) {
      nodes {
        name
        value
      }
    }
  }
`;
